<template>
  <div>
    <v-form ref="form" class="support-form">
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            :items="types"
            :label="$t('contact_support.type')"
            :menu-props="{ contentClass: 'types-column-menu', bottom: true, offsetY: true }"
            v-model="form.type"
            dense
            filled
            hide-details
            item-text="label"
            item-value="value"
            id="types-select"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field :label="$t('contact_support.subject')" v-model="form.subject" dense filled hide-details />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea :label="$t('contact_support.description')" v-model="form.description" dense filled hide-details />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-file-input
            :label="$t('contact_support.attachments')"
            v-model="form.attachments"
            filled
            dense
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
            multiple
            :rules="[value => rules.maxFiles(value) || $t('contact_support.attachments_error')]"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <PrimaryButton @click="submit" class="support-submit-btn">
            {{ $t('contact_support.send') }}
          </PrimaryButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import PrimaryButton from '@/components/app/PrimaryButton'
import FileUploadHelper from '@/util/FileUploadHelper'
import { rules } from '@/validation/rules'
import Logger from '@/util/Logger'

export default {
  name: 'Support',
  components: {
    PrimaryButton
  },
  data() {
    return {
      types: [
        {
          label: '--',
          value: null
        },
        {
          label: this.$t('contact_support.type_feedback'),
          value: 'Feedback'
        },
        {
          label: this.$t('contact_support.type_support'),
          value: 'Support'
        },
        {
          label: this.$t('contact_support.type_report_a_bug'),
          value: 'Report a bug'
        }
      ],
      form: {
        type: '',
        name: '',
        email: '',
        subject: '',
        description: '',
        attachments: []
      },
      rules: { ...rules }
    }
  },
  methods: {
    submit() {
      const fileUploadHelper = new FileUploadHelper()
      fileUploadHelper.addData('type', this.form.type)
      fileUploadHelper.addData('subject', this.form.subject)
      fileUploadHelper.addData('description', this.form.description)

      if (this.form.attachments.length > 0) {
        this.form.attachments.forEach(attachment => {
          fileUploadHelper.addData('attachments[]', attachment)
        })
      }

      return this.$store
        .dispatch('contact/store', fileUploadHelper.getUploadData())
        .then(() => {
          this.$store.dispatch('snackbar/showSuccessMessage', { message: this.$t('contact_support.success_message') })
        })
        .catch(error => {
          Logger.log('contact error', error)
        })
    }
  }
}
</script>
