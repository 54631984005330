<template>
  <div id="support">
    <Headline :text="$t('contact_support.heading')" />
    <SupportIndex />
  </div>
</template>

<script>
import Headline from '@/components/app/Headline'
import SupportIndex from '@/components/support/SupportIndex'

export default {
  name: 'Index',
  components: {
    Headline,
    SupportIndex
  }
}
</script>
